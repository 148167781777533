#page-landing{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#page-landing .content-wrapper{
    width: 100%;
    max-width: 1100px;
    height: 100%;
    max-height: 680px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    color: #000000;
}

.content-wrapper .enter-app {
    right: 0;
    bottom: 0;
    position: absolute;
    width: 80px;
    height: 80px;
    background-color: #F58323;
    border-radius:30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

.content-wrapper .enter-app:hover {
    background-color: #C2571A;
}