*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    color: #0764b1;
    background-color: #fff;
}

body, input, button, textarea {
    font: 600 18px Nunito, sans-serif;
}