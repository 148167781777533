aside.app-sidebar {
    width: 440px;
    background-color: #fff;
    padding: 70px;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  aside.app-sidebar h2 {
    font-size: 40px;
    font-weight: 800;
    line-height: 42px;
    margin-top: 64px;
  }
  
  aside.app-sidebar p {
    line-height: 28px;
    margin-top: 24px;
  }
  
  aside.app-sidebar footer {
    display: flex;
    flex-direction: column;
    line-height: 24px;
  }
  
  aside.app-sidebar footer strong {
    font-weight: 800px;
  }

  aside.app-sidebar main ul {
    margin: 10px;
  }

  aside.app-sidebar main ul li {
    list-style-type: none;
  }

  aside.app-sidebar main ul li a {
    text-decoration: none;
    color: #C2571A;
  }

  aside.app-sidebar main ul li a:hover {
    text-decoration: none;
    color: #f58323;
  }
  