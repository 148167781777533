#page-report {
  width: 100vw;
  height: 100vh;
  background-color: #eaeaea;
  position: relative;
  display: flex;
}

#page-report .mainReport {
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

#page-report main {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

#page-report main .divTitle {
  width: 100%;
  padding: 10px;
  color: #000000;
}

#page-report main .divFilters {
  margin: 10px;
  padding: 20px;
  color: #000000;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#page-report main .divFilters div {
  flex: 1;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

input[type=text] {
  width:100%;
  padding:10px;
  margin:10px 0;
  border: 0;
  border-bottom:1px solid #f58323; 
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
}

button {
  appearance:none;
  -webkit-appearance:none;
  padding:10px;
  border:none;
  background-color:#f58323;
  color:#fff;
  font-weight:600;
  border-radius:5px;
  width:100%;
}

.content-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 98%;
  color: #000;
}

.content-table thead tr {
  background-color: #f58323;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
}
.content-table td a {
  color: #C2571A;
}
.content-table td a:hover {
  color: #f58323;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #f58323;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #f58323;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
} 

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
            
.page-item {
  display: list-item;
  text-align: -webkit-match-parent;
  color: #C2571A;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #C2571A;
  background-color: #fff;
  border: 1px solid #f58323;
}

.page-link:hover {
  color: #f58323;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #f58323;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #f58323;
  border-color: #C2571A;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #C2571A;
}

.active {
  z-index: 1;
  color: #fff;
  background-color: #C2571A;
  border-color: #C2571A;
  /* color: #000000; */
}